.project_circles_stat1{
    

    
    /* min-width: 590px; */
    width: 96%;
    margin: 0 auto;
    margin-top: 4%;
    height: 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: 4%;
    /* overflow: hidden; */
}

.project_circles_stat_line1{
    
    height: 0px;
    width: 82%;
    border: 3px solid rgba(0, 0, 0, 0.3);
    position: absolute;
    z-index: -1;
    top: 15px;
    right: 8%;
}

.project_circles_stat_circle-block1{
    width: 60px;
}

.project_circles_stat_circle1{
    height: 40px;
    border-radius: 50%;
}


.project_circles_stat_onCircle1{
    position: absolute;
    margin: 19px;
    
}

.project_circles_stat_numberOfCircle1{
    font-weight: 700;
    margin-left: 5px;
    font-family: ALS Sirius;
    font-size: 16px;
}

.project_circles_stat_labels_main_green1, .project_circles_stat_labels_main_gray1{
    text-align: center;
    font-family: Inter;
    font-weight: 700;
    font-size: min( 16px, calc(12px + 4 * ((100vw - 320px) / 1024)));
    height: 40px;
    width: 114px;
    margin-left: -35px;
    display: block;
    color:rgb(127, 127, 127);
    white-space: pre-wrap;
    
   
    outline: none;
    border: 0;
    background: transparent;
    cursor: pointer;
}

.project_circles_stat_labels_date_green1, .project_circles_stat_labels_date_gray1{
    display: block;
    width: 100px;
    height: 40px;
    font-family: Inter;
    font-size: min( 10px, calc(8px + 4 * ((100vw - 320px) / 1024)));
    font-weight: 700;
    line-height: 15px;
    color:rgb(127, 127, 127);
    margin-left: -9px;
    margin-top: 6px;
    height: 18px;
    width: 60px;
}

.project_circles_stat_labels_date_gray1, .project_circles_stat_labels_main_gray1{
    color:rgb(127, 127, 127);
}


@media (max-width: 770px){
    .project_circles_stat_labels_main_green1, .project_circles_stat_labels_main_gray1{
        font-size: min( 16px, calc(12px + 2 * ((100vw - 660px) / 1024)));
        margin-left: -24px;
        width: 90px;
    }
    .project_circles_stat_labels_date_green1, .project_circles_stat_labels_date_gray1{
        margin-left: -2px;
        width: 50px;
        margin-top: 2px;
    }
    
}

@media (max-width: 473px) {

}