
.counter {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-family: Inter;
    font-size: 14px;
    opacity: 0.6;
    font-weight: 700;
}

.page_myprofil_class{
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 600px;
    margin: 0 auto;
    height: auto;
    margin-bottom: 3%;
    /* position: relative; */
    /* z-index: 0; */
}

.profile_myprofile_My {
    margin-top: 22px;
    display:block;
    height: 5%;
    min-height: 38px;
    color: #28BE46;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
}

.profile_myprofile_top_container{
    display: flex;
    justify-content: space-between;
}

.profile_myprofile_left{
    width: 32%;
    min-width: 120px;
}

.profile_myprofile_right{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    justify-content:space-between;
}


.profil_myprofil_img_photo {
    display: block;
    width: 100%;
}
.avatar_circle {
   position: relative;
   height: 100%;
   width: 100%;
   overflow: hidden;
   cursor: pointer;
   border-radius: 50%;
   background-color: rgb(224, 224, 224);
}

.mobile_avatar_circle{
    display: none;
}
.avatar_stub {
    position: absolute;
    top: 10%;
    width: 100%;

    transition: 500ms;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.avatar_circle:hover .avatar_stub{
    margin-top: 10px;
    width: 80%;
    opacity: 0.5;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.avatar_stub>svg path{
    transition: fill 500ms;
}
.avatar_circle:hover>svg path{
    fill: rgba(0, 0, 0, 0.25);;   
}
.change_avatar_text{
    position: absolute;
    top: 35%;
    width: 100%;
    text-align: center;

    color: rgba(0, 0, 0, 0.62);
    font-family: ALS Sirius;
    font-size: 16px;
    font-weight: 700;
    opacity: 0;
}
.avatar_circle:hover >.change_avatar_text{
    top:40%;
    opacity: 1;
    transition: 500ms;
}




/* Main columns */
.profil_myprofil_top_right_form_block {
    display: block;
    width:100%;
    border-radius: 4px;
}

.profil_myprofil_top_right_form_block>input {
    border:none;
    width: 100%;
    box-sizing: border-box;
    height: 42px;
    display: block;
    padding-left: 20px;
    background-color: rgba(29, 25, 25, 0.10);
    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 400;
    border-radius: 4px;
}

.profil_myprofil_edit {
    margin-top: 20px;
    display: block;
    /* width: 186px; */
    width: 100%;
    transition: 500ms;
}

.profil_myprofil_edit:hover {
    background-color: rgba(0, 0, 0, 0.10);
}

.profil_myprofil_edit>button {
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.30);
    /* width: 188px; */
    width: 100%;
    height: 45px;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
    color: rgba(0, 0, 0, 0.56);
    background-color: rgba(29, 25, 25, 0.10);
    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}

.myprofil_edit_text {
    color: rgba(0, 0, 0, 0.56);
    font-family: ALS Sirius;
    font-size: 12px;
    font-weight: 400;
    /* position: absolute; */
    margin-left: 10px;
}

.myprofil_edit_img {
    width: 20px;
    height: 20px;
    opacity: 0.56;
    position: absolute;
    margin-left: -15px;
}

.myprofil_edit_text_mob{
    display: none;
}

/* Выключает подсветку всех input */
input {
    outline: none;
}


.profil_myprofil_exp {
    position: relative;
    margin-top: 29px;
    flex-shrink: 0;
    border-radius: 8px;
    border:none;
    background: rgba(29, 25, 25, 0.10);
    width: 100%;
    box-sizing: border-box;
}

.profil_myprofil_exp_area {
    resize: none;
    outline: none;
    border: none;
    background: rgba(29, 25, 25, 0);
    font-family: ALS Sirius;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    margin-left: 20px;
    width: 96%;
    height: 92px;
    margin: 2% 3% 22px 3%;
}

.profil_myprofil_skill {
    position: relative;
    margin-top: 29px;
    border-radius: 8px;
    background: rgba(29, 25, 25, 0.10);
    width: 100%;
    box-sizing: border-box;
    border: none;
}

.profil_myprofil_skill_area {
    outline: none;
    resize: none;
    border: none;
    overflow:hidden;
    overflow-y: scroll;
    display: inline-block;

    margin: 2% 3% 22px 3%;

    background: rgba(29, 25, 25, 0);
    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 400;
    width: 96%;
    height: 92px;
}

.profil_myprofil_about {
    position: relative;
    margin-top: 53px;
    border-radius: 8px;
    border: none;
    background: rgba(29, 25, 25, 0.10);
    
}

.profil_myprofil_about_area{
    margin: 2% 3% 22px 3%;
    display: inline-block;
    background: rgba(29, 25, 25, 0);
    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 400;
    border: none;
    outline: none;
    width: 96%;
    /* Ширина поля в процентах */
    height: 213px;
    /* Высота поля в пикселях */
    resize: none;
    overflow:hidden;
    overflow-y: scroll;
}
/* for scroll */
.profil_myprofil_area_scrollbar::-webkit-scrollbar{
    width: 5px;
    height: 90%;
}
.profil_myprofil_area_scrollbar::-webkit-scrollbar-track{
    background-color: rgb(232, 232, 232);
}
.profil_myprofil_area_scrollbar::-webkit-scrollbar-thumb{
    background-color:rgb(56, 56, 56);
    border-radius: 10px;
}

.profil_myprofil_save {
    margin: 44px auto 0 auto;
    display: block;
    width: 212px;
    height: 52px;
    padding: 13px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #229F3B;
    border: none;
    color: #FFF;
    font-family: ALS Sirius;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

@media (max-width: 480px) {
    .myprofil_edit_text_desc{
        display: none;
    }
    .myprofil_edit_text_mob{
        font-size: 11px;
        display: block;
    }
    .profile_myprofile_right{
        width: 56%;
    }
    .profil_myprofil_top_right_form_block input::placeholder{
        font-size: 14px;
    }
}
/* @media (max-width: 480px) {
    .page_myprofil_class{
        border: 1px solid #000;
        width: 80%;
    }
    .profile_myprofile_My {
        font-size: 26px;
        border: 1px solid #000;
    }
    .profile_myprofile_left{
        width: 30%;
        border: 1px solid #ff0000;
    }
    .profile_myprofile_right{
        width: 70%;
        border: 1px solid #51ff00;
    }
    .profil_myprofil_img_photo {
        min-height: none;
        border: 1px solid #000;
    }
    .avatar_stub {
        border: 1px solid #000;
        width: 100%;
        height: 130px;
    }
    .avatar_circle {
        display: none;
    }
    .mobile_avatar_circle{
        display: block;
        margin: 0 auto;
    }

    .profil_myprofil_edit{
        border: 1px solid #4400ff;
        width: 90%;
        margin: 0 auto;
    }

    .myprofil_edit_text{
        border: 1px solid #000;
        height: auto;
        hyphens: auto;
    }

    .myprofil_edit_text {
        font-size: 10px;
    }
    
    .myprofil_edit_img {
        width: 10px;
        height: 10px;
        opacity: 0.56;
        position: absolute;
        margin-left: -15px;
    }

} */

@media (max-width: 380px) {
    .page_myprofil_class{
        width: 310px;
        height: 300vh;
    }
    
}