.cont-search{
    position: relative;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    border : none;
}
.insearch{
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    font-family: ALS Sirius;
    width: 100%;
    height: 62px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.2);

}
.insearch>input{
    color: #000;
    font-family: ALS Sirius;
}
.search-btn{
    width: auto;
    min-width: 32px;
    height: 80%;
    margin-top:16px ;
    margin-left:17px ;    
    margin-right: 15px;
    margin-bottom: 15px;
    font-family: ALS Sirius;
}
.search-btn svg {
    position: absolute;
    top: 20%;
    height: 60%;
    stroke: #6D6D6D;
    transition: 0.4s;
}

.input-search{
    outline: none;
    border : none;
    width: 80%;
    height: 62px;
    line-height: 62px;
    font-family: ALS Sirius;
    font-size: min( 32px, calc(18px + 8 * ((100vw - 320px) / 1024)));
    font-weight: 400;
    color: rgba(0, 0, 0, 0.40);
    display: inline-flex;
    border-radius: 8px;
    background: rgba(0, 0, 0,0);
    margin-left: 1%;
}
.input-search::placeholder{
    font-family: ALS Sirius;
    font-size: min( 32px, calc(18px + 8 * ((100vw - 320px) / 1024)));
}

.insearch:hover ::placeholder{
    color: rgba(0, 0, 0, 0.8);
    transition: 0.4s;
}
.insearch:not(:hover) ::placeholder{
    transition: 0.4s;
}
.insearch:hover .search-btn svg{
    stroke: #000;
    transition: 0.4s;
}

input:focus::placeholder {
    color: transparent;

}
input:focus::placeholder {
    opacity: 0
}
.insearch:hover .search-btn{
    color: #000;
}

.input-search:focus ~ .search-btn svg{
    stroke: #000;

}

@media (max-width: 754px) {
    .input-search{font-size: 24px; margin-top: 0.2%; margin-left: -1.5%;}
    .input-search::placeholder{
        position: absolute;
        left: 9%;
        top: 14%
    }
    .insearch{
        width: 100%;
    }
}

@media (max-width: 480px) {
    .input-search{font-size: 18px; margin-top: 0.2%; margin-left: -3.5%;}
    .insearch{
        width: 100%;
        height: 42px;
    }
    .insearch>input{
        color: #000;
        font-family: ALS Sirius;
    }
    .search-btn{
        width: 28px;
        margin-top:10px ;
    }
    .input-search{
        width: 100%;
        height: 42px;
    }
    .input-search::placeholder{
        position: absolute;
        left: 6%;
        top: 1%
    }
}