.main_flexBox{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: auto;
}

/* #over_navbar:hover .dark{
    background-color: #000;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    opacity: 0.5;
} */

.top_cont_forUIpage{
    position: relative;
}

.body_cont_forUIpage{
    position: relative;
    display: flex;
    flex-direction: row;
    height: auto;
    min-height: 200vh;
}
