body::-webkit-scrollbar{
    width: 12px;
}
body::-webkit-scrollbar-track{
    background-color: rgb(232, 232, 232);
}
body::-webkit-scrollbar-thumb{
    background-color:rgb(56, 56, 56);
    border-radius: 5px;
}

#over{
    position: relative;
    background-color: #ffffff;
    z-index: 1009;
}

.header_myprofil {
    display: flex;
    line-height: 112px;
    width: 100vw;
    height: 112px;
    align-items: center;
    background: rgba(29, 25, 25, 0.10);
    justify-content: space-between;
    position: relative;
    user-select: none;

}

.logo_myprofil{
    display: flex;
    width: 20%;
    height: 100%;
    align-items: center;
    text-align: center;
}

.mobile{
    display: none;
}


.ver_for_pc{
    display: flex;
}

.logo_myprofil>img{
    height: 64px;
}

.Myprofil_header_name{
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    margin-right: 2vw;
}
.Myprofil_name{
    color: rgba(0, 0, 0, 0.56);
    font-family: ALS Sirius;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin-right:20px;
    text-decoration:none;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    text-align: end;
    float: right;
    position: relative;
    width: max-content;
}
.Myprofil_name_main{
    padding-bottom: 20px;
    
}
.sub_profil_name{
    font-family: ALS Sirius;
    font-size: 14px;
    width: max-content;
    right: 20px;
    font-weight: 700;
    line-height: 24.76px;
    text-align: left;
    float: right;
    position: absolute;
    top: 20px;
}


a{
    text-decoration:none;
}

.Myprof_icon{
    width: 66px;
    height: 66px;
}

/* рамочка кнопки "войти" */
.btn-modal {
    position: absolute;
    right: 50px;
    top: 30px;
    width: 160px;
    height: 48px;
    align-items: center;
    border-radius: 7px;
    background: #28BE46;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    transition: 500ms; 
    cursor: pointer;
}
.btn-modal:hover {
    background:#229F3B;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

}
/* "войти" */
 .Text{
    margin: 0 auto;
    width: 100px;
    color: rgba(0, 0, 0, 0.80);

    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
 }

 @media (max-width: 754px) {
    .Myprofil_header_name{
        margin-right: 20px;
    }
    .header_myprofil {
        height: 85px;
    }
    .btn-modal{
        top: 18px ;
        right: 20px;
        width: 120px;
    }
    .mobile{
        display: flex; 
        height:100%;
        width: 20%;
        margin-left: 2%;
    }
    .ver_for_pc{display: none;}
    .logo_myprofil img {
        display: flex;
        align-items: center;
        width: 200px;
        height: 100%;
    }
    .Myprof_icon svg{
        width: 48px;
        margin-right: 0;
    }
    .Myprofil_header_name{
        margin-right: -1vw;
    }
    .Myprofil_name{
        font-size: 16px;
        margin-right: 5px;
    }
    .sub_profil_name{
        right: 5px;
    }
 }

 @media (max-width: 380px) {
    .header_myprofil{
        height: 70px;
    }
    .Myprofil_header_name{
        margin-right: 3vw;
        padding-right: 0;
        box-sizing: border-box;
    }
    .Myprof_icon{margin-right: 0;padding-right: 0;width: max-content;}
    .Myprofil_name{
        font-size: 16px;
        margin-right: 5px;
    }
    .sub_profil_name{
        right: 5px;
    }
    body {
        width: 380px;
        overflow-x: scroll;
    }
    .header_myprofil {
        width: 380px;
    }
    .btn-modal {
        left: 230px;
        width: 120px;
        text-align: center;
    }
    .logo_myprofil{
        width: 164px;
    }
    .logo_myprofil img {
        width: 164px;
    }
    .Myprof_icon svg{
        width: 38px;
        margin-right: 0;
        padding-right: 0;
    }
}