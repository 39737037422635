@font-face {
    font-family: "ALS Sirius";
    src: url("/src/Shrifts/ALS_Sirius_Regular_0.95.otf") format("opentype");
}
@font-face {
    font-family: "Inter";
    src: url("/src/Shrifts/ALS_Sirius_Bold_0.95.otf") format("opentype");
}
@font-face {
    font-family: "ALSTPU";
    src: url("/src/Shrifts/ALSTPU.otf") format("opentype");
}

.b {
    font: bolder;
    rotate: 45deg;
}

.cont-sort{
    width: 100%;
    min-height: 896px;
    height: max-content;
    overflow: hidden;
    /* padding-bottom: 1%; */
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.1);
}
 
.sort{
    position: relative;
    display: flex;
    flex-direction: column;
}

.menu_item_name{
    margin-left: 16px;
    width: 100%;
    height: 83px;
    color: rgba(40, 190, 70, 1);
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
}

.Fil{
    position: relative;
    top: 30px;
    margin-top: 0px;
    margin-left: 21px;
    width: 100%;
    height: 52px;
    line-height: 52px;
    color: #000;
    font-family: ALS Sirius;
    font-size: 32px;
    font-weight: 400;
    transition: 500ms;  
}

.Fil_inside{
    user-select: none;
    width: 100%;
    height: 50px;
    max-height: 50px;
    padding: 0 0 2% 0;
    margin-top: -65px;
    background: rgba(0, 0, 0, 0.05);
    overflow:hidden;
    transition: 700ms;
    /* transition: max-height 0.7s cubic-bezier(0,.42,.51,1.89); */
    /* transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);    */

    overflow: hidden;
}

.new_background{
    background-color: rgb(217,217,217);
    height: max-content;
    padding-bottom: 5%; 
    /* transition: 1.5s cubic-bezier(0, 1, 0, 1); */

    max-height: 1000px;
    transition: 700ms;
    /* transition: max-height 0.001s cubic-bezier(0,-0.01,0,.99);  */
}

.new_background .Arrow_fil{
    rotate: 180deg;
    transition: 500ms;
}

.Arrow_fil{
    width: 36px; 
    height: 13px;
    float: right;
    margin-top: 20px;
    margin-right: 8px;
    background: rgba(0, 0, 0, 0);  
    border: none;
    cursor: pointer;
    transition: 500ms;
}

.menu_item_name_inside{
    color: rgba(0, 0, 0, 0.80);

    text-align: center;
    font-family: ALS Sirius;
    font-size: 14px;
    font-weight: 400;
    
    margin-left: 19px;
    margin-top: 16px;
    padding: 2% 3%;
    /* height: 28px; */
    flex-shrink: 0;
    border-radius: 4px;
    background: rgba(29, 25, 25, 0.15);
    border: none;
    cursor: pointer;
}

@media (min-width: 480px) and (max-width: 754px) {
    .cont-sort{
        min-height: 440px;
    }
    .menu_item_name{
        font-size: 16px;
        height: 73px;
    }
    
    .Fil{
        top: 15px;
        margin-left: 12px;
        width: 100%;
        height: 32px;
        line-height: 32px;
        font-size: 26px;
    }
    
    .Fil_inside{
        height: 30px;
    }
    
    .new_background{
        background-color: rgb(217,217,217);
        height: max-content;
        padding-bottom: 5%; 
        transition: 1.5s cubic-bezier(0, 1, 0, 1);
    }
    
    .Arrow_fil{
        width: 30px;
        margin-top: 10px;
        margin-right: 8px;
    }
    
    .menu_item_name_inside{
        color: rgba(0, 0, 0, 0.80);
    
        text-align: center;
        font-family: ALS Sirius;
        font-size: 10px;
        font-weight: 400;
        
        margin-left: 6px;
        margin-top: 10px;
        background: rgba(29, 25, 25, 0.15);
        border: none;
        cursor: pointer;
    }
}