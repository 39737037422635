.page_myproject_class{
    max-width: 957px;
    width: 80%;
    margin: 0 auto;
    height: 120vh;
}

.profil_myproject_top_container{
    display: flexbox;
}

.profil_myproject_My{
    display: block;

    color: #28BE46;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 110px;
}

.profil_myproject_tasks, .profil_myproject_tasks2{
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
    background-color: #cccccc;;
}

.profil_myproject_tasks2{
    background-color: #e6e6e6;
    border-radius: 0 0 8px 8px;
}



.profil_myproject_task, .profil_myproject_task2{
    width: 34%;
    gap: 10px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content:center;
}

.fat_boy{
    background-color: #00000010;
}


.profil_myproject_task>p, .profil_myproject_task2>p{
    display: inline-block;
    width: max-content;
    text-wrap: nowrap;
    height: 72px;
    justify-content: center;
    color: #000;
    
    font-family: ALS Sirius;
    font-size: 20px;
    font-weight: 700;
    line-height: 72px;
}

.profil_myproject_task2>p{
    width: max-content;
    height: 52px;
    line-height: 52px;
}

.profil_myproject_task_img{
    height:40px;
    width: 40px;
    display: inline-block;
}

.profil_myproject_SUB_bottom_container{
    display: flex;
    justify-content: space-between;
}


@media (max-width: 750px) {

    .profil_myproject_task_img{
        align-items: center;
        display: inline-flex;
        height: 35px;
        width: 35px;
    }
}

@media (max-width: 577px) {

    .profil_myproject_task_img{
        align-items: center;
        display: inline-flex;
        height: 30px;
        width: 30px;
    }
    .profil_myproject_task>p, .profil_myproject_task2>p{
        font-size: 16px;
        height: 52px;
        line-height: 52px;
    }
}

@media (max-width: 480px) {

    .profil_myproject_task>p, .profil_myproject_task2>p{
        font-size: 12px;
        height: 52px;
        line-height: 52px;
    }
    .profil_myproject_task_img{
        height: 25px;
        width: 25px;
    }
}